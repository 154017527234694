import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link, StaticQuery} from 'gatsby'
import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'

export const IndexPageTemplate = ({
  title,
  heading,
  image,
}) => (
<div>
  <div class="landing-video-cover-intro">
    <video autoPlay muted playsinline >
      <source src="img/Rocket.mp4" type="video/mp4"/>
      <source src="img/Rocket.webm" type="video/webm"/>
    </video>
  </div>
  <section className="header">
    <div className="header-title">
    <h1 data-aos="fade-left" data-aos-delay="400" data-aos-duration="2000">{heading}
    </h1>
    <Link to="/about" data-aos="fade-left" data-aos-delay="600" data-aos-duration="2000" className="main-button">
       Read More
    </Link>
    </div>
    <img src="img/home.png" data-aos="fade-right" data-aos-delay="200" data-aos-duration="2000" className="image-header" alt="home-image"/>
  </section>

  <section className="projects">
      <BlogRoll />
  </section>
    
  <section className="overview">
  <div className="overview-text">
    
    <h2 data-aos="fade-right" data-aos-delay="200" data-aos-duration="2000">Overview</h2>
    <p data-aos="fade-right" data-aos-delay="400" data-aos-duration="2000">ZM Land & Capital is a private UK property development company focussed on acquiring unconsented brownfield sites and securing planning permissions.</p>
    <Link to="/about" data-aos="fade-right" data-aos-delay="600" data-aos-duration="2000"className="main-button">
       Read More
    </Link>
    </div>

    <div className="overview-image" data-aos="fade-left" data-aos-delay="800" data-aos-duration="2000">
    <img src="img/overview.jpg" />
      </div>
    
  </section>

  <section className="blurb">
  <div className="blurb-text">
    
    <h3 data-aos="fade-right" data-aos-delay="400" data-aos-duration="2000">We operate UK wide and target strong growth locations with good transport links. Committed funding lines mean that we can provide a quick response regarding potential purchase opportunities, while our unique blend of expertise and experience enables us to identify and optimise the final outcome for every acquisition.</h3>
    </div>

    <div data-aos="fade-left" data-aos-delay="200" data-aos-duration="2000" className="blurb-image">
    <p>We have long term funding partners with whom we have successfully undertaken a wide range of projects, meeting agreed investment criteria and maximising value and returns.<br /><br />

The Directors have over 60 years’ experience in the UK property market and a proven track record delivering commercial, residential and mixed use projects. Schemes have included: substantial residential led developments in London, masterplanned brownfield and waterside sites, office refurbishments, permitted development and complex mixed use schemes.</p>
    <img src="img/chilterns.png" />
      </div>
    
  </section>
</div>
    
)

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  mainimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        image={frontmatter.image}
        projectlist={frontmatter.projectlist}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`